import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, type, name, pictureUrl, siteUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  return (
    <Helmet 
      htmlAttributes={{
        lang,
      }}
      
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: pictureUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
       <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Proceed GmbH & Co KG",
          "alternateName": "Proceed GmbH",
          "url": "https://www.proceed.gmbh",
          "logo": "/src/images/logo/Logo_Proceed.png",
          "description": "Proceed begleitet mittelständische Unternehmen durch die Transformation hin zu einer schlagkräftigen und werteorientierten Organisation.",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+497618976183",
            "contactType": "customer service",
            "contactOption": "HearingImpairedSupported",
            "areaServed": "DE",
            "availableLanguage": "German"
          },
          "sameAs": [
            "https://proceed.gmbh",
            "https://www.facebook.com/team.proceed/",
            "https://www.linkedin.com/in/michael-kirsch-03078784/"
          ]
        }
      `}</script>
      
      {title == "Das Experten-Team für Kulturwandel im Unternehmen" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Proceed GmbH & Co KG",
          "alternateName": "Proceed GmbH",
          "url": "https://www.proceed.gmbh",
          "description": "Proceed begleitet mittelständische Unternehmen durch die Transformation hin zu einer schlagkräftigen und werteorientierten Organisation.",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.proceed.gmbh/#website",
            "url": "https://www.proceed.gmbh/",
            "name": "Proceed GmbH & Co KG",
            "contactType": "customer service",
            "inLanguage": "de-DE"
            }
          },{
            "@type": "BreadcrumbList",
            "itemListElement": {
              "@type": "ListItem",
              "position": 1,
              "item": {
                "@type": "WebPage",
                "@id": "https://www.proceed.gmbh",
                "url": "https://www.proceed.gmbh",
                "name": "Das Experten-Team für Kulturwandel im Unternehmen"
              }
            }
          }]
        }
      `}</script>
      }
      {title == "Beratung - Human, rentabel, anpassungsfähig" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Proceed Beratung - Human, rentabel, anpassungsfähig",
          "alternateName": "Proceed GmbH",
          "url": "https://www.proceed.gmbh/beratung",
          "description": "Proceed passt zu Ihnen, wenn unsere Zusammenarbeit einer gemeinsamen Vision folgt: Ihr Unternehmen als schlagkräftige und anpassungsfähige Organisation.",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.proceed.gmbh/#website",
            "url": "https://www.proceed.gmbh/",
            "name": "Proceed GmbH & Co KG",
            "inLanguage": "de-DE"
            }
          },{
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.proceed.gmbh",
              "url": "https://www.proceed.gmbh",
              "name": "Das Experten-Team für Kulturwandel im Unternehmen"
            }
          },{
            "@type": "ListItem",
            "position": 2,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.proceed.gmbh/beratung",
              "url": "https://www.proceed.gmbh/beratung",
              "name": "Beratung - Human, rentabel, anpassungsfähig"
            }
          }]
        }]
        }
     
      `}</script>
      }
      {title == "Akademie - Bringen Sie Ihr Unternehmen auf den Weg" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Proceed Akademie - Bringen Sie Ihr Unternehmen auf den Weg",
          "alternateName": "Proceed GmbH",
          "url": "https://www.proceed.gmbh/akademie",
          "description": "Weg von Hierarchie, Abteilungsdenken und schleppenden Abläufen. Hin zu Teamarbeit, flexiblen Strukturen und intelligenten Prozessen. Ziel ist eine anpassungsfähige Organisation, in der Menschen gerne und gemeinsam arbeiten.",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.proceed.gmbh/#website",
            "url": "https://www.proceed.gmbh/",
            "name": "Proceed GmbH & Co KG",
            "inLanguage": "de-DE"
            }
          },{
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.proceed.gmbh",
              "url": "https://www.proceed.gmbh",
              "name": "Das Experten-Team für Kulturwandel im Unternehmen"
            }
          },{
            "@type": "ListItem",
            "position": 2,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.proceed.gmbh/akademie",
              "url": "https://www.proceed.gmbh/akademie",
              "name": "Akademie - Bringen Sie Ihr Unternehmen auf den Weg"
            }
          }]
        }]
        }
     
      `}</script>
      }
      {title == "Wissen muss fließen" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Proceed Blog - Wir teilen Wissen",
          "alternateName": "Proceed GmbH",
          "url": "https://www.proceed.gmbh/wissenswertes",
          "description": "Wir teilen Wissen zu New Work, Agilität und Transformation und begleiten Sie auf dem Weg zu einem robusten und flexiblen Unternehmen.",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.proceed.gmbh/#website",
            "url": "https://www.proceed.gmbh/",
            "name": "Proceed GmbH & Co KG",
            "inLanguage": "de-DE"
            }
          },{
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.proceed.gmbh",
              "url": "https://www.proceed.gmbh",
              "name": "Das Experten-Team für Kulturwandel im Unternehmen"
            }
          },{
            "@type": "ListItem",
            "position": 2,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.proceed.gmbh/wissenswertes",
              "url": "https://www.proceed.gmbh/wissenswertes",
              "name": "Wissen muss fließen"
            }
          }]
        }]
        }
     
      `}</script>
      }
      {title == "Das Proceed-Team" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Proceed Team - Ein Netzwerk erfahrener Experten",
          "alternateName": "Proceed GmbH",
          "url": "https://www.proceed.gmbh/blog",
          "description": "Im Begleiterteam praktizieren wir selbst, was wir unseren Mandanten empfehlen: Die jeweils geeigneten Experten gruppieren sich um ein Projekt und führen es selbstorganisiert zu einem Ergebnis. ",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.proceed.gmbh/#website",
            "url": "https://www.proceed.gmbh/",
            "name": "Proceed GmbH & Co KG",
            "inLanguage": "de-DE"
            }
          },{
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.proceed.gmbh",
              "url": "https://www.proceed.gmbh",
              "name": "Das Experten-Team für Kulturwandel im Unternehmen"
            }
          },{
            "@type": "ListItem",
            "position": 2,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.proceed.gmbh/team",
              "url": "https://www.proceed.gmbh/team",
              "name": "Das Proceed-Team"
            }
          }]
        }]
        }
     
      `}</script>
      }
      {title == "Kooperationen" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Proceed Team - Ein Netzwerk erfahrener Experten",
          "alternateName": "Proceed GmbH",
          "url": "https://www.proceed.gmbh/blog",
          "description": "Im Begleiterteam praktizieren wir selbst, was wir unseren Mandanten empfehlen: Die jeweils geeigneten Experten gruppieren sich um ein Projekt und führen es selbstorganisiert zu einem Ergebnis. ",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.proceed.gmbh/#website",
            "url": "https://www.proceed.gmbh/",
            "name": "Proceed GmbH & Co KG",
            "inLanguage": "de-DE"
            }
          },{
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.proceed.gmbh",
              "url": "https://www.proceed.gmbh",
              "name": "Das Experten-Team für Kulturwandel im Unternehmen"
            }
          },{
            "@type": "ListItem",
            "position": 2,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.proceed.gmbh/kooperationen",
              "url": "https://www.proceed.gmbh/kooperationen",
              "name": "Kooperationen"
            }
          }]
        }]
        }
     
      `}</script>
      }
      
      </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO


