import React, { useState } from 'react';
import { Link } from 'gatsby';
import { dark,  mq, darkgrey} from '../../styles/globalstyles';
import {motion} from 'framer-motion';
import {css} from '@emotion/react';


const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    initial: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

const TeamCard = (props) => {
    const [cardState, setCardState] = useState(false)
    return (
            <motion.div onMouseEnter={() => setCardState(true) } onMouseLeave={() => setCardState(false)}  variants={variants} css={css`width: 16em; margin: 2em; position: relative; `}> 
              
                    <motion.div className="boxShadow" animate={cardState ? {scale: 1.01} : {scale: 1}} css={css`height: 20em; width: 16em; border-radius: 2em 0 2em 0; overflow: hidden; position: relative;`}>
                    <Link to={"/team/" + props.props.vorname.toLowerCase() + "-" + props.props.nachname.toLowerCase()}  css={{position: "absolute", width:"100%", height: "100%", opacity: 0, zIndex: 1}}>
                      { props.props.vorname.toLowerCase() + "-" + props.props.nachname.toLowerCase()}
                    </Link>
                        <img height="auto" width="100%"  align="top" src={props.props.portrait.url} css={css`postion: relative; height: 100%; object-fit: cover;`} /> 
                    </motion.div>
                    
                    <div css={mq({ width: "100%", overflow:" hidden", padding: "0.2em", marginTop: "1.6em", display: "flex", flexDirection: "column", flexWrap: "wrap", alignItems: "flex-start", justifyContent: "flex-start" })}>
                        <h4 css={css`color: ${dark} !important; margin-right: 0.3em; margin-bottom: 0.2em; display: inline;`}>{props.props.titel ? props.props.titel  : null} {props.props.vorname} {props.props.nachname}  </h4>
                        {props.props.bezeichnung ?  <h6 css={{color: darkgrey, transform: "translate(0,-5px)", display: "block"}}>{props.props.bezeichnung} <br /> </h6> : null}
                        {props.props.firmenname ?
                          <h6 css={{margin: "0.6em 0"}}>{props.props.firmenname}</h6>
                            :
                            null
                        }
                        
                        <p >
                          {props.props.schlagwort.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                          })} </p>
                    </div>
            </motion.div> 

    )

}


export default TeamCard;