import React from 'react';
import {FlexContainer, Wrapper, teamsite, teamsiteTop, FlexBox, FlexContainerRow, mq, background1, background3, orange, Container, siteheader} from '../styles/globalstyles'
import TeamCard from '../components/team/teamcard';
import { gql, useQuery } from "@apollo/client";
import {motion} from 'framer-motion';
import SEO from '../components/seo';
import { MediumOverline } from '../components/overline';
import { TeamIconNoHover } from '../components/team/teampanel';
import {css} from '@emotion/react';


const variants = {
  enter: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  initial: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const TEAMMEMBERS = gql`
{
    teams(where: {teamseite: true}) {
        id
        titel
        vorname
        nachname
        bezeichnung
        schlagwort
        firmenname
        firmenadresse
        portrait{
            id
            url(transformation: { image: {resize: {width: 340, height: 430, fit: crop}}})
        }
        eMail
    }
}`;


const Team = (props) =>{
  const {data} = useQuery(TEAMMEMBERS)
  return(
      <>
      <Wrapper background={background1}> 
        <Container> 
          <SEO 
          title="Das Proceed-Team" 
          pictureUrl={props.pageContext.contextData.metaImages[0].team.url} 
          description="Im Begleiterteam praktizieren wir selbst, was wir unseren Mandanten empfehlen: Die jeweils geeigneten Experten gruppieren sich um ein Projekt und führen es selbstorganisiert zu einem Ergebnis. " />
          <FlexContainerRow direction="row" align="center" css={[siteheader]} >
          <FlexBox direction="column" css={css` overflow: visible; padding: 1em;`}>
              <MediumOverline>Unser Netzwerk</MediumOverline>
                <h1>Experten fürs Ganze</h1>
                <h5>Als Transformationsbegleiter setzen wir im Detail an und behalten dabei das ganze Unternehmen im Blick. Unser Expertennetzwerk liefert Wissen und Erfahrung zu harten wie weichen Themen.</h5>
              </FlexBox>
              <FlexBox direction="row" align="center" justify="center" css={mq({height: "100%", minHeight: "18em", position: "relative", flexWrap: "wrap", display: ["none", "none", "flex"]})} >
                <div css={mq({marginLeft: "4em", width: "100%", height: "100%",  position: "absolute", })}>
                  {(data) &&  data.teams.map((value, index) =>{
                  return <TeamIconNoHover id={value.id} key={index} number={index} />
                  })
                  }
                </div>
              </FlexBox>
          </FlexContainerRow>
        </Container>
      </Wrapper>

      <Wrapper background={background3}>
        <Container>
          <FlexContainer direction="column" align="center">
            <div css={{order: 2,  marginTop: "2em"}}> 
            {(data) ? 
              <motion.div variants={variants} initial="initial" animate="enter" css={teamsiteTop} >
                {(data.teams[1]) && <TeamCard key={data.teams[0].nachname} props={data.teams[0]}></TeamCard> }
                {(data.teams[0]) && <TeamCard key={data.teams[1].nachname} props={data.teams[1]}></TeamCard> }
              </motion.div> : <FlexContainer css={css`height: 100vh;`} />
              
            }
            </div>
            <div css={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", order: 1, maxWidth: "1070px", textAlign: "center"}} > 
              <h2><span css={{color: orange}}>Nachhaltig begeistert</span> für Mittelständler</h2>
              <p css={mq({width: ["80%", "80%", "80%", "80%"] })}>Ökonomisches Handeln mit ökologischer und sozialer Wirkung: Diesem Ziel haben wir uns mit PROCEED verschrieben. In vielen mittelständischen Unternehmen ist breite Nutzenstiftung ohnehin Tradition. Sie übernehmen Verantwortung. Für den Standort und seine Menschen. Diese Verantwortung in ein zukunftssicherndes Geschäftsmodell zu überführen, dafür bren-nen wir. Als Biologen mit jahrzehntelanger Führungserfahrung bringen wir die Erfolgsrezepte der Natur mit dem Potenzial werteorientierter Arbeit zusammen.  
              </p>
            </div>
          </FlexContainer>
        </Container>
      </Wrapper>

      <Wrapper background={background1}>
        <Container>
          <FlexContainer direction="column" align="center"> 
              <div css={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", order: 3, maxWidth: "1070px", textAlign: "center"}}>
                <h2>Vier Perspektiven und Disziplinen: <br /> <span css={{color: orange}}>Die Netzwerkpartner</span></h2>
                <p css={mq({width: ["80%", "80%", "80%", "80%"] })}>Einer für alles im Unternehmen? Diesen heldenhaften Unternehmensberater bewundern wir. Bei unserer Beratung sind wir realistischer und setzen auf Fachleute mit Weitblick. Deren Spezialwissen bringt verlässlich Ergebnisse. Ihr bewährtes Teamplay bringt die ganze Organisation voran.
                </p>
              </div>
            {(data) ? 
              <motion.div variants={variants} initial="initial" animate="enter" css={[teamsite, {order: 4}]} >
                {(data.teams[2]) && <TeamCard key={data.teams[2].nachname} props={data.teams[2]}></TeamCard> }
                {(data.teams[3]) && <TeamCard key={data.teams[3].nachname} props={data.teams[3]}></TeamCard> }
                {(data.teams[4]) && <TeamCard key={data.teams[4].nachname} props={data.teams[4]}></TeamCard> }
                {(data.teams[5]) && <TeamCard key={data.teams[5].nachname} props={data.teams[5]}></TeamCard> }
                {(data.teams[6]) && <TeamCard key={data.teams[6].nachname} props={data.teams[6]}></TeamCard> }
                {(data.teams[7]) && <TeamCard key={data.teams[7].nachname} props={data.teams[7]}></TeamCard> }
              </motion.div> : <FlexBox css={css`height: 100vh;`} />
            }
          </FlexContainer>
        </Container>
      </Wrapper>
    </>
    )

}

export default Team; 